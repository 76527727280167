body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
fieldset {
  background-color: #eeeeee;
  padding: 0px 0px 10px 10px;
  border-radius: 5px;
  border-style: solid;
}

legend {
  color: black;
  font-weight:bold;
  width:auto;
}
.igroup{
  margin: 15px 0px 0px 0px;
}
.recipe-instructions{
  margin-top: 10px;
}
.recipe-categories{
  margin-bottom: 10px;
}
.iformErrors{
  margin-bottom: 10px;
}
.current-book{
  color:orange;
}
#active-book{
  margin:0px 0px 0px 15px;
}
#add-book{
  margin:0px 0px 0px 15px;
}
.login-message{
  margin:40px 0px 0px 50px;
}
.dishTypeHeader{
  font-weight: bold;
  background-color:rgb(213, 247, 213);
}
#welcome{
  margin: 10px 0px 0px 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.app-title{
  display: inline-block;
  margin: 0px 0px 0px 5px;
  vertical-align: bottom;
  font-family:'Roboto';
  font-size: 37px;
}
.app-logo{
  margin: 0px 0px 0px 0px;
}
#add-recipe {
  margin: 10px 0px 0px 10px;
}
#recipe-books {
  margin: 10px 0px 0px 10px;
}
#show-recipes {
  margin: 10px 0px 0px 10px;
}
#app-header{
  margin: 0px 0px 10px 10px;
}
#maf-signin{
  display:inline-flex;
  padding: 5px 0px 20px 5px;
  vertical-align:baseline;
}
#g-customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 150px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#g-customBtn:hover {
  cursor: pointer;
}
#g-label {
  font-family: serif;
  font-weight: normal;
}
#g-normal {
  background: url('g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
#g-buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}
#g-name{
  padding: 10px 0px 0px 5px;
}